import { useI18nDateTimeFormats, useI18nNumberFormats, FALLBACK_LOCALE_POLICY } from '@yescapa-dev/ysc-components/i18n'

export default defineI18nConfig(() => {
  // Fallback locale map
  const fallbackLocale = FALLBACK_LOCALE_POLICY

  // Number formats
  const numberFormats = useI18nNumberFormats()

  // Datetime formats
  const datetimeFormats = useI18nDateTimeFormats()

  // vue-i18 config
  return {
    legacy: false,
    fallbackLocale,
    datetimeFormats,
    numberFormats,
    missingWarn: import.meta.dev,
    warnHtmlMessage: false,
    fallbackWarn: false,
  }
})
